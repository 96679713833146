import { Icon } from './Icon'

import iconChevron from '../images/icons/chevron-down.raw.svg'

import styles from './Faq.css'

export function FaqWrapper({ children }) {
  return (
    <div className={styles.component}>
      {children}
    </div>
  )
}

export function FaqItem({ summary, children }) {
  const [open, setOpen] = React.useState(false)

  return (
    <details className={styles.componentFaqItem} {...{ open }}>
      <summary className={styles.questionSummary} onClick={toggleFaq} >
        {summary}
        <div className={cx(styles.chevron, open && styles.chevronOpen)}><Icon icon={iconChevron} /></div>
      </summary>
      <div className={styles.questionAnswer}>
        {children}
      </div>
    </details>
  )

  function toggleFaq(e) {
    e.preventDefault()
    setOpen(!open)
  }
}
