import groq from 'groq'

const linkableDocumentFields = groq`
  _type,
  slug,
  language,
  _id,
  _type == 'subvakgebied' => {'vakgebiedSlug': vakgebied->slug }`
const linkableDocumentObject = groq`{${linkableDocumentFields}}`
const linkableDocumentObjectWithTitle = groq`{ title, ${linkableDocumentFields} }`
const referenceWithLabelObject = groq`{_type, label, ref->${linkableDocumentObject}}`
const referenceWithLabelAllowedTechblogOverview = groq`{"_type": "referenceWithLabel", label, ref->${linkableDocumentObject}}`
const urlWithLabelObject = groq`{_type, label, href}`

const internalOrExternalLink = groq`{
  _type == 'internalLink' => { ..., ref->${linkableDocumentObject} },
  _type == 'externalLink' => {...},
}`

const contentSimpleObject = groq`{
  ...,
  markDefs[] {
    ...,
    _type == 'internalLink' => { ref->${linkableDocumentObject} }
  }
}`



const imageWithTextObject = groq`{
  image,
  position,
  title,
  blocks[] ${contentSimpleObject},
  link { ref->${linkableDocumentObject}, label },
  internalOrExternalLink[0]${internalOrExternalLink}
}`

const ctaWithRecruiterObject = groq`{
  link { ref->${linkableDocumentObject}, label },
  'recruiters': recruiters.recruiters[] -> {
    name,
    email,
    phone,
    linkedIn,
    photo
  }
}`

const simpleContentCardsObject = groq`{
  ...,
  items[] {
    ...,
    text[]${contentSimpleObject},
    internalOrExternalLink[0]${internalOrExternalLink}
  }
}`

const videoImagesObject = groq`{
  'videoImages' : imageGrid[0..1]
}`

const ctaToJobsObject = groq`{
  blocks[] ${contentSimpleObject},
  title,
  image,
}`

const downloadObject = groq`{
  title,
  file {
    asset-> {
      url,
      size,
      extension,
      originalFilename,
    }
  }
}`

const employeeObject = groq`{
  avatar,
  functionTitle,
  title,
  biography,
  linkedIn,
  github,
  webLink
}`

const traineeshipPropertiesObject = groq`{ customProperties[]-> {property}, startingSpots, departments, duration, salary, hours, place }`

const tileRepresentationObject = groq`{
  _id,
  _type,
  'label': coalesce(label, ^._type),
  _type == 'tileImage' => {
    'title': coalesce(title, ^.displayTitle,  ^.title),
    'image': coalesce(image, ^.featuredMedia[0], ^.featuredMedia)
  },
  _type == 'tileExpertise' => {
    'title': coalesce(title, ^.displayTitle, ^.title),
    'image': coalesce(image)
  },
  _type == 'tileVideo' => {
    'title': coalesce(title, ^.displayTitle, ^.title),
    'image': coalesce(image, ^.featuredMedia[0], ^.featuredMedia)
  },
  _type == 'tileQuote' => {
    quote,
    employee->${employeeObject}
  },
  _type == 'tilePodcast' => { 'title': coalesce(title, ^.displayTitle, ^.title) },
  _type == 'tileStatistic' => { statistic, description },
}`

const subvakgebiedenObject = groq`{
  title,
  content[] ${contentSimpleObject},
  subvakgebieden[]->{
    'title': coalesce(displayTitle, title),
    subtitle,
    ${linkableDocumentFields},
    "vakgebied": *[_type=='vakgebied' && ^._id in subvakgebieden.subvakgebieden[]._ref][0]${linkableDocumentObject},
  }
}`

const recruiterObject = groq`{
  photo,
  name,
  email,
  role,
  linkedIn,
  phone,
}`

const techblogRepresentationObject = groq`{
  'representation': {
    _id,
    'label': 'techblog',
    '_type': 'tileTechblog',
    'title': coalesce(displayTitle, title),
    'employee': employees[0]->${employeeObject}
  }
}`

const tilesObject = groq`{
  _type == 'reference' => @->{
    ${linkableDocumentFields},
    title,
    employees[]->${employeeObject},
    _type != 'techblog' => { 'representation': tileRepresentation[0] ${tileRepresentationObject} },
    _type == 'techblog' => ${techblogRepresentationObject},
    _type == 'subvakgebied' => { vakgebied-> },
  },
  _type == 'tileLink' => {
    '_type': @['_type'],
    'representation': @{
      _type,
      label,
      title,
      internalOrExternalLink[0]${internalOrExternalLink}
    }
  },
  _type == 'tileStatistic' => {
    '_type': @['_type'],
    'representation': @{
      _type,
      description,
      statistic,
    },
  },
  _type == 'tileQuote' => {
    '_type': @['_type'],
    'representation': @{
      _type,
      quote,
      employee->${employeeObject},
      internalOrExternalLink[0]${internalOrExternalLink}
    },
  },
  _type == 'tileSkillsmatch' => {
    '_type': @['_type'],
    'representation': @{
      _type,
      label,
      image,
      title
    },
  }
}`

const contentObject = groq`{
  ...,
  _type == 'anchor' => { id },
  _type == 'introWithTitleType' => { title, introduction[]${contentSimpleObject} },
  _type == 'download' => ${downloadObject},
  _type == 'imageWithContent' => ${imageWithTextObject},
  _type == 'imagesWithContentSlides' => {items[]${imageWithTextObject}},
  _type == 'ctaWithRecruiter' => ${ctaWithRecruiterObject},
  _type == 'simpleContentCards' => ${simpleContentCardsObject},
  _type == 'cta' => {
    image,
    internalLink ${referenceWithLabelObject},
    label,
    internalOrExternalLink[0]${internalOrExternalLink}
  },
  _type == 'textWithVideo' => {
    internalOrExternalLink[0]${internalOrExternalLink},
    orientation,
    hideOverlay,
    video,
    text[] ${contentSimpleObject},
  },
  _type == 'internalLink' => ${referenceWithLabelObject},
  _type == 'ctaWithTitleAndButton' => {
    internalLink ${referenceWithLabelObject},
    internalOrExternalLink[0]${internalOrExternalLink}
  },
  _type == 'rewardCalculator' => {
    title,
    content,
    internalLink ${referenceWithLabelObject},
  },
  _type == 'rewardCalculatorCta' => {
    title,
    internalLink ${referenceWithLabelObject},
  },
  _type == 'faqs' => {
    blocks[] ${contentSimpleObject},
    questions[]{
      ...,
      blocks[] ${contentSimpleObject},
    }
  },
  _type == 'faqList' => {
    questions[]{
      ...,
      blocks[] ${contentSimpleObject},
    }
  },
  _type == 'tiles' => {
    'tiles': tiles[]${tilesObject}
  },
  _type == 'techblogSlider' => {
    'tiles': tiles[]${tilesObject}
  },
  _type == 'videoPreview' => {
    'preview': {
      previewUrl,
      videoUrl,
      poster
    }
  },
  _type == 'wavemakersVideo' => {
    src,
    title,
    srcHlsUrl,
    mobilePreviewHlsUrl,
    desktopPreviewHlsUrl,
    mobilePoster,
    desktopPoster,
    playButtonHint
  },
  _type == 'videoPreviewAnchor' => {
    'anchor': { state }
  },
  _type == 'tilesWithTextAndRelatedContent' => {
    label,
    blocks,
    'tiles': tiles[]${tilesObject}
  },
  _type == 'linksBlock' => {
    links[]{
      _type,
      title,
      description,
      link->${linkableDocumentObject}
    }
  },
  _type == 'quickLinks' => {
    title,
    'links': internalOrExternalLink[]${internalOrExternalLink}
  },
  _type == 'jobList' => {
    title,
    description,
    referenceWithLabel ${referenceWithLabelObject},
    internalOrExternalLink[0]${internalOrExternalLink},
  },
  _type == 'jobs' => {
    ...
  },
  _type == 'ctaToTraineeship' => {
    title,
    "subtitle": traineeshipPageRef->title,
    image,
    text,
    urlWithLabel,
    "properties": traineeshipPageRef->properties${traineeshipPropertiesObject},
    "openCloseLabel" : traineeshipPageRef->openCloseLabel.label
  },
  _type == 'factsAndFigures' => {
    "properties": traineeshipPageRef->properties${traineeshipPropertiesObject},
  },
  markDefs[] {
    ...,
    _type == 'internalLink' => { ref->${linkableDocumentObject} }
  }
}`

const panelObject = groq`{
  title,
  content[] ${contentObject},
  backgroundImage,
  backgroundColor,
  'tiles': tiles[]${tilesObject},
}`

const singleDocumentFilter = groq`
  *[_type == $type && language == $language && slug.current == $slug] |
  order(_updatedAt desc) [0]
`

const vakgebieden = groq`*[_type == 'vakgebied' && language == $language]{ _type, title, category, slug, language }`

const topicDetailObject = groq`
  _id,
  _updatedAt,
  _createdAt,
  _type,
  'title': coalesce(displayTitle, title),
  'readMore': internalOrExternalLink[0]${internalOrExternalLink},
  content[] ${contentObject},
  _type == 'topicPodcast' => { podcast },
  _type == 'topicArticle' => { hero },
  _type == 'topicVideo' => { videoWithChapters },
  _type == 'topicFact' => { fact },
  'tag': tags[]-> {
    'label_en': coalesce(displayTitle.en, title),
    'label_nl': coalesce(displayTitle.nl, title),
    'code': slug.current,
  },
  seo
`

const homeGridObject = groq`{
  blocks,
  title,
  openSkillsMatch,
  internalLink ${referenceWithLabelObject},
  'tiles': tiles[]${tilesObject}
}`

// primary and secondary are required to have the correct order when sending them to the datalayer.
const taxonomyObject = groq`{
  skills,
  vakgebied,
  proofPoints {
    primary,
    secondary
  }
}`

const traineeshipDetailObject = groq`{
  'title': coalesce(displayTitle, title),
  intro[] ${contentSimpleObject},
  openCloseLabel,
  properties ${traineeshipPropertiesObject},
  openDate,
  startDate,
  typeOfTraineeship,
  'slug': slug.current,
}`

export const snippets = {
  linkableDocumentFields,
  linkableDocumentObjectWithTitle,
  linkableDocumentObject,
  contentObject,
  contentSimpleObject,
  tilesObject,
  referenceWithLabelObject,
  referenceWithLabelAllowedTechblogOverview,
  urlWithLabelObject,
  imageWithTextObject,
  employeeObject,
  recruiterObject,
  tileRepresentationObject,
  subvakgebiedenObject,
  vakgebieden,
  panelObject,
  videoImagesObject,
  ctaToJobsObject,
  topicDetailObject,
  homeGridObject,
  taxonomyObject,
  traineeshipPropertiesObject,
  traineeshipDetailObject,
  singletonDocument(type, projection, { language }) {
    return [
      groq`
          *[_type == $type && language == $language] |
          order(_updatedAt desc) [0] ${projection}
        `,
      { type, language }
    ]
  },
  document(type, projection, { language, slug }) {
    return [
      groq`${singleDocumentFilter} ${projection}`,
      { type, language, slug }
    ]
  },
  subvakgebied(projection, { language, parentSlug, slug }) {
    return [
      groq`
      *[_type == $type && language == $language && slug.current == $slug && $parentSlug == *[_type == 'vakgebied' && slug.current == $parentSlug][0].slug.current] |
        order(_updatedAt desc) [0]
        ${projection}
      `,
      { type: 'subvakgebied', language, parentSlug, slug }
    ]
  },
  translations(projection) {
    return groq`
        *[
          _id != ^._id &&
          _type == ^._type &&
          translationId == ^.translationId
        ]
        ${projection}
      `
  },
}
